import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CLocalizedText from '../../../components/CLocalizedText';
import CStatefulContainer from '../../../components/CStatefulContainer';
import StatefulModuleDisabler from '../../../components/StatefulModuleDisabler';
import GhostPostGalleryLinkButton from '../../../components/dashboardContent/GhostPostGalleryLinkButton';
import Page from '../../../components/layout/Page';
import PageModule from '../../../components/layout/PageModule';
import XDPaginator from '../../../components/layout/XDPaginator';
import XDResponsiveGridWrapper from '../../../components/layout/XDResponsiveGridWrapper';
import { ActionsEnumDeprecate } from '../../../enums/actionsEnumDeprecate';
import { PageRoutes } from '../../../enums/enums';
import { useAppDispatch } from '../../../hooks/hooks';
import type { GhostPostList, GhostTags } from '../../../models/GhostPostModel';
import type { IRequestState } from '../../../models/IRequestState';
import NewsService from '../../../services/newsService';
import { updateActiveAction, updateSnackbar } from '../../../store/appSlice';

interface State {
  posts?: GhostPostList;
  postsReqState: IRequestState;
  tags?: GhostTags;
}

export default function ArticlesIndex() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<State>({
    postsReqState: { isLoading: true, isError: false }
  });
  const updateState = (obj: Partial<State>) => {
    setState((state) => ({ ...state, ...obj }));
  };
  const { posts, tags, postsReqState } = state;

  const clickedOnPost = () => {
    dispatch(updateActiveAction(ActionsEnumDeprecate.n_post_stateful));
  };

  const fetchPosts = async (page: number, tag?: string) => {
    updateState({ postsReqState: { ...postsReqState, isLoading: true } });
    try {
      const res = await NewsService.getAllPosts(page.toString(), tag);
      updateState({
        posts: res,
        postsReqState: { isLoading: false, isError: false }
      });
      if (res.meta.pagination.pages > 0 && res.meta.pagination.page > res.meta.pagination.pages) {
        // If page exceeds all available pages, bring user to first page
        await fetchPosts(1, tag);
      }
    } catch (e) {
      updateState({ postsReqState: { isLoading: false, isError: true } });
    }
  };

  const clickedOnTagHandler = (tag: string, pg: number) => {
    setSearchParams({ tag, page: pg.toString() });
  };

  const loadTags = async () => {
    try {
      const tags = await NewsService.getAllTags();
      updateState({ tags });
    } catch (e) {
      dispatch(updateSnackbar({ severity: 'error', message: 'Failed to load tags' }));
    }
  };

  useEffect(() => {
    let cPage = Number.parseInt(searchParams.get('page') ?? '1');
    if (Number.isNaN(cPage)) {
      cPage = 1;
    }
    (async () => {
      await fetchPosts(cPage, searchParams.get('tag') ?? '');
    })();
  }, [searchParams]);

  useEffect(() => {
    (async () => {
      await loadTags();
    })();
  }, []);

  return (
    <Page>
      <PageModule>
        <h1>
          <CLocalizedText dictKey={'newsArticles'} />
        </h1>
        {posts && (
          <StatefulModuleDisabler disabled={postsReqState.isLoading}>
            <div className={'flex gap-2 flex-col flex-grow'}>
              <p className={'uppercase text-sm font-bold mt-1 opacity-80'}>
                <CLocalizedText dictKey={'newsFilterByTag'} />
              </p>
              <div className={'flex gap-5 gap-y-2 md:gap-y-1 items-center flex-wrap text-xl md:text-lg'}>
                <TagButton
                  slug={''}
                  label={<CLocalizedText dictKey={'newsTagAll'} />}
                  isSelected={(searchParams.get('tag') ?? '') === ''}
                  onClick={() => {
                    clickedOnTagHandler('', 1);
                  }}
                />
                {tags?.tags.map((e) => (
                  <TagButton
                    key={e.slug}
                    isSelected={searchParams.get('tag') === e.slug}
                    label={e.name}
                    slug={e.slug}
                    onClick={() => {
                      clickedOnTagHandler(e.slug, 1);
                    }}
                  />
                ))}
              </div>
            </div>
          </StatefulModuleDisabler>
        )}

        {posts?.posts && posts.posts.length === 0 && (
          <Alert severity={'info'}>There was no article found matching the requested criteria</Alert>
        )}
        <CStatefulContainer apiRequestState={postsReqState}>
          <XDResponsiveGridWrapper className={'gap-[0px]'} borderlessContent>
            <>
              {posts?.posts?.map((x, ix) => (
                <GhostPostGalleryLinkButton
                  key={ix}
                  post={x}
                  pathPrefix={`../../${PageRoutes.NEWS_OUTLET}/${PageRoutes.NEWS_ARTICLES_OUTLET}`}
                  onClick={() => {
                    clickedOnPost();
                  }}
                />
              ))}
            </>
          </XDResponsiveGridWrapper>
        </CStatefulContainer>
        <XDPaginator
          apiReqState={postsReqState}
          pagination={posts?.meta.pagination}
          retrievePageCallback={(x) => {
            setSearchParams({
              ...Object.fromEntries([...searchParams]),
              page: x.toString()
            });
          }}
        />
      </PageModule>
    </Page>
  );
}

interface TagButtonProps {
  slug: string;
  label: string | JSX.Element;
  isSelected: boolean;
  onClick: (slug: string) => void;
}

function TagButton({ slug, label, isSelected, onClick }: TagButtonProps) {
  return (
    <a
      className={`mb-0 ${isSelected ? 'text-teal-ppt-1' : 'font-normal'}`}
      onClick={() => {
        onClick(slug);
      }}
    >
      {label}
    </a>
  );
}
