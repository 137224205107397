import { Alert } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CFactsheetDisclaimer from '../../components/CFactsheetDisclaimer';
import CHalfWidthSunburstPlot from '../../components/CHalfWidthSunburstPlot';
import CHorizontalScrollContainer from '../../components/CHorizontalScrollContainer';
import CLocalizedText from '../../components/CLocalizedText';
import CLocalizedTextString from '../../components/CLocalizedTextString';
import CPageModule from '../../components/CPageModule';
import CPageModulePaginatedTable from '../../components/CPageModulePaginatedTable';
import CPageModulePlot from '../../components/CPageModulePlot';
import CPandasTable from '../../components/CPandasTable';
import CSectionNavigator from '../../components/CSectionNavigator';
import CStatefulContainer from '../../components/CStatefulContainer';
import CTab from '../../components/CTab';
import CTabItem from '../../components/CTabItem';
import Page from '../../components/layout/Page';
import { PageRoutes } from '../../enums/enums';
import { updateState, useStateExtended } from '../../helpers/helper';
import { useAppSelector } from '../../hooks/hooks';
import { type PaginatedBackendResponse } from '../../models/GhostPostModel';
import { type IAugmentedBackendResponse } from '../../models/IAugmentedBackendResponse';
import { type IRequestState } from '../../models/IRequestState';
import { type ISortingMeta } from '../../models/ISortingMeta';
import { type ITableOrPlotState } from '../../models/ITableOrPlotState';
import { type TPandasTable } from '../../models/TPandasTable';
import { type IFactsheetFactorExposurePlots } from '../../models/factsheet/IFactsheetFactorExposurePlots';
import PublicFactsheetServices from '../../services/publicFactsheetServices';

interface State {
  factorExposureRequestState: IRequestState;
  factorExposure?: IFactsheetFactorExposurePlots;
  universeRequestState: IRequestState;
  universe?: PaginatedBackendResponse<Record<string, Record<string, any>>>;
  universeSortBy?: string;
  swapRequestState: IRequestState;
  swap?: PaginatedBackendResponse<Record<string, Record<string, any>>>;
  swapSortBy?: string;
  showNavigatorBanner: boolean;
  universeCfer: ITableOrPlotState<IAugmentedBackendResponse<TPandasTable>>;
  swapCfer: ITableOrPlotState<IAugmentedBackendResponse<TPandasTable>>;
  factorExposureTable: ITableOrPlotState<{ universe: TPandasTable; swap: TPandasTable }>;
}

export default function PFactorExposure() {
  const { locale } = useAppSelector((state) => state.dashboard);
  const { factsheetId } = useParams();
  const [state, setState, getState] = useStateExtended<State>({
    factorExposureRequestState: { isLoading: true, isError: false },
    universeRequestState: { isLoading: true, isError: false },
    swapRequestState: { isError: true, isLoading: true },
    showNavigatorBanner: false,
    universeCfer: {
      state: {
        isError: false,
        isLoading: true
      },
      response: undefined,
      params: { sorting: { sortCol: 3, ascending: 'descending' } }
    },
    swapCfer: {
      state: {
        isError: false,
        isLoading: true
      },
      response: undefined,
      params: { sorting: { sortCol: 3, ascending: 'descending' } }
    },
    factorExposureTable: {
      state: {
        isError: false,
        isLoading: true
      },
      response: undefined,
      params: { sorting: { sortCol: 3, ascending: 'descending' } }
    }
  });

  const sectionNavigator = (
    <CSectionNavigator
      previous={{
        url: `../${PageRoutes.PUBLIC_FACTSHEET_PERFORMANCE}`,
        label: <CLocalizedText dictKey={'fsovP2Performance'} />
      }}
      next={{
        url: `../${PageRoutes.PUBLIC_FACTSHEET_SECTOR_EXPOSURE}`,
        label: <CLocalizedText dictKey={'globalFactsheetSectionSecex'} />
      }}
    />
  );

  const fetchUniverseCfer = async (page: number, sorting: ISortingMeta, searchText: string) => {
    const latestState = (await getState()).universeCfer;
    updateState<State>(
      { universeCfer: { ...latestState, state: { ...latestState.state, isLoading: true } } },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getFactorExposureCfer(
      factsheetId ?? '',
      'universe',
      page,
      sorting,
      searchText,
      locale
    );
    if (res.wasSuccessful()) {
      updateState<State>(
        {
          universeCfer: {
            response: res.getData(),
            state: { isLoading: false, isError: false },
            params: { search: searchText, sorting }
          }
        },
        state,
        setState
      );
    } else {
      updateState<State>(
        {
          universeCfer: {
            ...latestState,
            state: { isError: true, isLoading: false, strError: res.getErrorString(), objError: res.getErrorObj() }
          }
        },
        state,
        setState
      );
    }
  };

  const fetchSwapCfer = async (page: number, sorting: ISortingMeta, searchText: string) => {
    const latestState = (await getState()).swapCfer;
    updateState<State>(
      { swapCfer: { ...latestState, state: { ...latestState.state, isLoading: true } } },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getFactorExposureCfer(
      factsheetId ?? '',
      'swap',
      page,
      sorting,
      searchText,
      locale
    );
    if (res.wasSuccessful()) {
      updateState<State>(
        {
          swapCfer: {
            response: res.getData(),
            state: { isLoading: false, isError: false },
            params: { search: searchText, sorting }
          }
        },
        state,
        setState
      );
    } else {
      updateState<State>(
        {
          swapCfer: {
            ...latestState,
            state: { isError: true, isLoading: false, strError: res.getErrorString(), objError: res.getErrorObj() }
          }
        },
        state,
        setState
      );
    }
  };

  const fetchFactorExposureTable = async () => {
    const latestState = (await getState()).factorExposureTable;
    updateState<State>(
      { factorExposureTable: { ...latestState, state: { ...latestState.state, isLoading: true } } },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getFactorExposureTable(factsheetId ?? '', locale);
    if (res.wasSuccessful()) {
      updateState<State>(
        {
          factorExposureTable: {
            response: res.getData(),
            state: { isLoading: false, isError: false },
            params: {}
          }
        },
        state,
        setState
      );
    } else {
      updateState<State>(
        {
          factorExposureTable: {
            ...latestState,
            state: { isError: true, isLoading: false, strError: res.getErrorString(), objError: res.getErrorObj() }
          }
        },
        state,
        setState
      );
    }
  };

  useEffect(() => {
    (async () => {
      await fetchUniverseCfer(
        state.universeCfer.response?.meta.pagination.page ?? 1,
        {
          sortCol: state.universeCfer.params.sorting?.sortCol ?? 3,
          ascending: state.universeCfer.params.sorting?.ascending ?? 'descending'
        },
        state.universeCfer.params.search ?? ''
      );
      await fetchSwapCfer(
        state.swapCfer.response?.meta.pagination.page ?? 1,
        {
          sortCol: state.swapCfer.params.sorting?.sortCol ?? 3,
          ascending: state.swapCfer.params.sorting?.ascending ?? 'descending'
        },
        state.swapCfer.params.search ?? ''
      );
      await fetchFactorExposureTable();

      updateState<State>({ factorExposureRequestState: { isError: false, isLoading: true } }, state, setState);
      const res = await PublicFactsheetServices.getFactorExposurePlots(factsheetId ?? '', locale);
      updateState<State>(
        { factorExposureRequestState: { isLoading: false, isError: res.hasError(), strError: res.getErrorString() } },
        state,
        setState
      );
      if (!res.hasError()) {
        updateState<State>({ factorExposure: res.getData() }, state, setState);
      }
    })();
  }, [locale]);

  return (
    <Page title={CLocalizedTextString('globalFactsheetSectionFacex')}>
      <CPageModule>{sectionNavigator}</CPageModule>
      <CPageModule>
        <h2>
          <CLocalizedText dictKey={'fsfacexP1H1'} />
        </h2>
        <CLocalizedText dictKey={'fsfacexP1Desc'} />
      </CPageModule>
      <CPageModule>
        <h3>
          <CLocalizedText dictKey={'fsfacexP3H1'} />
        </h3>
        <p>
          <CLocalizedText dictKey={'fsfacexP3Desc'} />
        </p>
        <CStatefulContainer showContent={!!state.factorExposure} apiRequestState={state.factorExposureRequestState}>
          <div className={'flex items-end flex-wrap gap-8 justify-center '}>
            <div>
              <CHalfWidthSunburstPlot
                title={<CLocalizedText dictKey={'globalInvestableUsUniverse'} />}
                data={state.factorExposure?.sunburstuniverseallocationweight.data}
                layout={state.factorExposure?.sunburstuniverseallocationweight.layout}
              />
            </div>
            <div>
              <CTab centerTabButtons>
                <CTabItem label={'factorAllocation'} text={<CLocalizedText dictKey={'globalAllocationWeight'} />}>
                  <CHalfWidthSunburstPlot
                    title={<CLocalizedText dictKey={'globalNexdosSwapAlloc'} />}
                    data={state.factorExposure?.sunburstswapallocationweight.data}
                    layout={state.factorExposure?.sunburstswapallocationweight.layout}
                  />
                </CTabItem>
                <CTabItem
                  label={'marketCapitalization'}
                  text={<CLocalizedText dictKey={'globalMarketCapitalization'} />}
                >
                  <CHalfWidthSunburstPlot
                    title={<CLocalizedText dictKey={'globalNexdosSwapAlloc'} />}
                    data={state.factorExposure?.sunburstswapmarketcap.data}
                    layout={state.factorExposure?.sunburstswapmarketcap.layout}
                  />
                </CTabItem>
              </CTab>
            </div>
          </div>
        </CStatefulContainer>
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fsfacexP6H1'} />
          </h3>
          <CLocalizedText dictKey={'fsfacexP6Desc'} />
        </CPageModule>
        <CStatefulContainer
          submodule
          showContent={!!state.factorExposureTable.response}
          apiRequestState={state.factorExposureTable.state}
        >
          <CTab submodule>
            <CTabItem label={'universe'} text={<CLocalizedText dictKey={'globalInvestableUsUniverse'} />}>
              <CHorizontalScrollContainer>
                <CPandasTable data={state.factorExposureTable.response?.universe} />
              </CHorizontalScrollContainer>
            </CTabItem>
            <CTabItem label={'swap'} text={<CLocalizedText dictKey={'globalNexdosSwapAlloc'} />}>
              <CHorizontalScrollContainer>
                <CPandasTable data={state.factorExposureTable.response?.swap} />
              </CHorizontalScrollContainer>
            </CTabItem>
          </CTab>
        </CStatefulContainer>
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fsfacexP2H1'} />
          </h3>
          <CLocalizedText dictKey={'fsfacexP2Desc'} />
        </CPageModule>
        <CTab submodule>
          <CTabItem label={'universe'} text={<CLocalizedText dictKey={'globalInvestableUsUniverse'} />}>
            <CPageModulePaginatedTable
              searchBarName={'universe'}
              state={state.universeCfer.state}
              data={state.universeCfer.response}
              params={state.universeCfer.params}
              onChange={async (page, sorting, searchText) => {
                await fetchUniverseCfer(page, sorting, searchText);
              }}
              colSpanArr={[3, 1, 2, 1, 1, 1, 1, 1]}
              cellHeight={'3.5rem'}
            />
          </CTabItem>
          <CTabItem label={'swap'} text={<CLocalizedText dictKey={'globalNexdosSwapAlloc'} />}>
            <CPageModulePaginatedTable
              searchBarName={'swap'}
              state={state.swapCfer.state}
              data={state.swapCfer.response}
              params={state.swapCfer.params}
              onChange={async (page, sorting, searchText) => {
                await fetchSwapCfer(page, sorting, searchText);
              }}
              colSpanArr={[3, 1, 2, 1, 1, 1, 1, 1]}
              cellHeight={'3.5rem'}
            />
          </CTabItem>
        </CTab>
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fsfacexP5H1'} />
          </h3>
          <CLocalizedText dictKey={'fsfacexP5Desc'} />
        </CPageModule>
        <CStatefulContainer
          showContent={!!state.factorExposure}
          apiRequestState={state.factorExposureRequestState}
          errorMessage={
            <CPageModule style={{ paddingTop: 0 }}>
              <Alert severity={'error'}>{state.factorExposureRequestState?.strError}</Alert>
            </CPageModule>
          }
        >
          <CTab submodule>
            <CTabItem label={'Momentum'} text={<CLocalizedText dictKey={'globalMomentum'} />}>
              <CPageModulePlot
                data={state.factorExposure?.momentum_histogram.data}
                layout={state.factorExposure?.momentum_histogram.layout}
              />
            </CTabItem>
            <CTabItem label={'Low Volatility'} text={<CLocalizedText dictKey={'globalLowVolatility'} />}>
              <CPageModulePlot
                data={state.factorExposure?.low_volatility_histogram.data}
                layout={state.factorExposure?.low_volatility_histogram.layout}
              />
            </CTabItem>
            <CTabItem label={'Profitability'} text={<CLocalizedText dictKey={'globalProfitability'} />}>
              <CPageModulePlot
                data={state.factorExposure?.profitability_histogram.data}
                layout={state.factorExposure?.profitability_histogram.layout}
              />
            </CTabItem>
            <CTabItem label={'Value'} text={<CLocalizedText dictKey={'globalValue'} />}>
              <CPageModulePlot
                data={state.factorExposure?.value_histogram.data}
                layout={state.factorExposure?.value_histogram.layout}
              />
            </CTabItem>
          </CTab>
        </CStatefulContainer>
      </CPageModule>
      {/* <CPageModule fullWidth> */}
      {/*   <CPageModule submodule> */}
      {/*     <h3> */}
      {/*       <CLocalizedText dictKey={'fsfacexP4H1'} /> */}
      {/*     </h3> */}
      {/*     <CLocalizedText dictKey={'fsfacexP4Desc'} /> */}
      {/*   </CPageModule> */}
      {/*   <CStatefulContainer */}
      {/*     showContent={!!state.factorExposure} */}
      {/*     apiRequestState={state.factorExposureRequestState} */}
      {/*     errorMessage={ */}
      {/*       <CPageModule style={{ paddingTop: 0 }}> */}
      {/*         <Alert severity={'error'}>{state.factorExposureRequestState?.strError}</Alert> */}
      {/*       </CPageModule> */}
      {/*     } */}
      {/*   > */}
      {/*     <CTab submodule> */}
      {/*       <CTabItem label={'Momentum'}> */}
      {/*         <CPageModulePlot */}
      {/*           data={state.factorExposure?.momentum_matrix.data} */}
      {/*           layout={state.factorExposure?.momentum_matrix.layout} */}
      {/*         /> */}
      {/*       </CTabItem> */}
      {/*       <CTabItem label={'Low Volatility'}> */}
      {/*         <CPageModulePlot */}
      {/*           data={state.factorExposure?.low_volatility_matrix.data} */}
      {/*           layout={state.factorExposure?.low_volatility_matrix.layout} */}
      {/*         /> */}
      {/*       </CTabItem> */}
      {/*       <CTabItem label={'Profitability'}> */}
      {/*         <CPageModulePlot */}
      {/*           data={state.factorExposure?.profitability_matrix.data} */}
      {/*           layout={state.factorExposure?.profitability_matrix.layout} */}
      {/*         /> */}
      {/*       </CTabItem> */}
      {/*       <CTabItem label={'Value'}> */}
      {/*         <CPageModulePlot */}
      {/*           data={state.factorExposure?.value_matrix.data} */}
      {/*           layout={state.factorExposure?.value_matrix.layout} */}
      {/*         /> */}
      {/*       </CTabItem> */}
      {/*     </CTab> */}
      {/*   </CStatefulContainer> */}
      {/* </CPageModule> */}
      <CPageModule>{sectionNavigator}</CPageModule>
      <CFactsheetDisclaimer />
    </Page>
  );
}
