import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CLocalizedText from '../../components/CLocalizedText';
import CLocalizedTextString from '../../components/CLocalizedTextString';
import CPageModule from '../../components/CPageModule';
import CPageModulePlot from '../../components/CPageModulePlot';
import CSectionNavigator from '../../components/CSectionNavigator';
import CStatefulContainer from '../../components/CStatefulContainer';
import Page from '../../components/layout/Page';
import { PageRoutes } from '../../enums/enums';
import { updateStateResponseStatefulAttribute } from '../../helpers/helper';
import { useAppSelector } from '../../hooks/hooks';
import { type IResponseStateful } from '../../models/IResponseStateful';
import { type PlotlyResponse } from '../../models/PlotlyModel';
import PublicFactsheetServices from '../../services/publicFactsheetServices';

interface State {
  overview: IResponseStateful<PlotlyResponse>;
  performanceComponents: IResponseStateful<PlotlyResponse>;
}

export default function PPerformanceAttribution() {
  const { factsheetId } = useParams();
  const { locale } = useAppSelector((state) => state.dashboard);
  const [state, setState] = useState<State>({
    overview: { state: { isLoading: true, isError: false } },
    performanceComponents: { state: { isLoading: true, isError: false } }
  });

  useEffect(() => {
    (async () => {
      updateStateResponseStatefulAttribute<State, typeof state.overview.data>(
        { overview: state.overview },
        setState,
        async () =>
          await PublicFactsheetServices.getPerformanceAttributionOverview(factsheetId ?? '', {
            locale: locale ?? 'de-DE'
          })
      );
      updateStateResponseStatefulAttribute<State, typeof state.performanceComponents.data>(
        { performanceComponents: state.performanceComponents },
        setState,
        async () =>
          await PublicFactsheetServices.getPerformanceAttributionPerformanceComponents(factsheetId ?? '', {
            locale: locale ?? 'de-DE'
          })
      );
    })();
  }, [locale]);

  const factsheetNavigator = (
    <CPageModule>
      <CSectionNavigator
        previous={{
          label: <CLocalizedText dictKey={'globalFactsheetSectionSecex'} />,
          url: `../${PageRoutes.PUBLIC_FACTSHEET_SECTOR_EXPOSURE}`
        }}
        next={{
          label: <CLocalizedText dictKey={'globalFactsheetSectionDelta'} />,
          url: `../${PageRoutes.PUBLIC_FACTSHEET_DELTA}`
        }}
      />
    </CPageModule>
  );

  return (
    <Page title={CLocalizedTextString('fsovP2Attribution')}>
      {factsheetNavigator}
      <CPageModule>
        <h1>
          <CLocalizedText dictKey={'fspaP1H1'} />
        </h1>
        <p>
          <CLocalizedText dictKey={'fspaP1Desc'} />
        </p>
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h2>{<CLocalizedText dictKey={'fspaP2H1'} />}</h2>
          <p>{<CLocalizedText dictKey={'fspaP2Desc'} />}</p>
        </CPageModule>
        <CStatefulContainer submodule apiRequestState={state.overview.state} showContent={!!state.overview.data}>
          <CPageModulePlot data={state.overview.data?.data} layout={state.overview.data?.layout} />
        </CStatefulContainer>
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h2>{<CLocalizedText dictKey={'fspaP3H1'} />}</h2>
          <p>{<CLocalizedText dictKey={'fspaP3Desc'} />}</p>
        </CPageModule>
        <CStatefulContainer
          submodule
          apiRequestState={state.performanceComponents.state}
          showContent={!!state.performanceComponents.data}
        >
          <CPageModulePlot
            data={state.performanceComponents.data?.data}
            layout={state.performanceComponents.data?.layout}
          />
        </CStatefulContainer>
      </CPageModule>
      {factsheetNavigator}
    </Page>
  );
}
