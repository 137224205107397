import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CHorizontalScrollContainer from '../../components/CHorizontalScrollContainer';
import CLocalizedText from '../../components/CLocalizedText';
import CPageModule from '../../components/CPageModule';
import CPageModulePlot from '../../components/CPageModulePlot';
import CPandasTable from '../../components/CPandasTable';
import CSortDropdown from '../../components/CSortDropdown';
import CStatefulContainer from '../../components/CStatefulContainer';
import CTab from '../../components/CTab';
import CTabItem from '../../components/CTabItem';
import GenericDateRangeSelector from '../../components/GenericDateRangeSelector';
import Page from '../../components/layout/Page';
import { updateStateAttribute, updateStateResponseStatefulAttribute, useStateExtended } from '../../helpers/helper';
import { useAppSelector } from '../../hooks/hooks';
import { type IResponseStateful } from '../../models/IResponseStateful';
import { type PlotlyResponse } from '../../models/PlotlyModel';
import { type TPandasTable } from '../../models/TPandasTable';
import { type MSingleStockSummarizedInfo } from '../../models/singleStock/details/MSingleStockSummarizedInfo';
import SvSingleStockServices from '../../services/SvSingleStockServices';
import BasicInfoModule from './singleStockReport/BasicInfoModule';
import CKeyEvents from './singleStockReport/CKeyEvents';

interface State {
  info: IResponseStateful<MSingleStockSummarizedInfo>;
  mcap: IResponseStateful<PlotlyResponse>;
  performanceDaily: IResponseStateful<{
    table: TPandasTable;
    chart: PlotlyResponse;
    startdate: string;
    enddate: string;
  }> & {
    initStartDate: number;
    initEndDate: number;
    minDate: number;
    maxDate: number;
  };
  performanceMonthly: IResponseStateful<{ table: TPandasTable; chart: PlotlyResponse }> & {
    initYear: string;
    yearRange: Record<string, string>;
  };
  performanceYearly: IResponseStateful<{ table: TPandasTable; chart: PlotlyResponse; bars: PlotlyResponse }> & {
    initYear: string;
  };
  volatility: IResponseStateful<PlotlyResponse>;
  drawdown: IResponseStateful<{ table: TPandasTable; chart: PlotlyResponse }>;
  dividends: IResponseStateful<PlotlyResponse>;
  tradingVolumeMarketCap: IResponseStateful<PlotlyResponse>;
  relativeTradingVolume: IResponseStateful<PlotlyResponse>;
}

export default function PSingleStockEntry() {
  const { identifier } = useParams();
  const { locale } = useAppSelector((state) => state.dashboard);

  const [state, setState, getState] = useStateExtended<State>({
    info: { state: { isLoading: true, isError: false } },
    mcap: { state: { isLoading: true, isError: false } },
    performanceDaily: {
      state: { isLoading: true, isError: false },
      initStartDate: 0,
      initEndDate: 0,
      minDate: 0,
      maxDate: 0
    },
    performanceMonthly: {
      state: { isLoading: true, isError: false },
      initYear: '0',
      yearRange: {}
    },
    performanceYearly: {
      state: { isLoading: true, isError: false },
      initYear: '0'
    },
    volatility: {
      state: { isLoading: true, isError: false }
    },
    drawdown: {
      state: { isLoading: true, isError: false }
    },
    dividends: { state: { isLoading: true, isError: false } },
    tradingVolumeMarketCap: { state: { isLoading: true, isError: false } },
    relativeTradingVolume: { state: { isLoading: true, isError: false } }
  });

  useEffect(() => {
    (async () => {
      const latestState0 = await getState();

      await updateStateResponseStatefulAttribute<State, typeof state.info.data>(
        { info: latestState0.info },
        setState,
        async () => await SvSingleStockServices.getSingleStockReportInfo(identifier)
      );
      await updateStateResponseStatefulAttribute<State, typeof state.mcap.data>(
        { mcap: latestState0.mcap },
        setState,
        async () => await SvSingleStockServices.getSingleStockReportMarketCap(identifier, { locale: locale ?? 'de-DE' })
      );
      await updateStateResponseStatefulAttribute<State, typeof state.performanceDaily.data>(
        { performanceDaily: latestState0.performanceDaily },
        setState,
        async () =>
          await SvSingleStockServices.getSingleStockReportPerformanceDaily({
            companyId: identifier ?? '',
            locale: locale ?? 'de-DE',
            startDate: latestState0.performanceDaily.initStartDate
              ? dayjs(latestState0.performanceDaily.initStartDate).format('YYYY-MM-DD')
              : undefined,
            endDate: latestState0.performanceDaily.initEndDate
              ? dayjs(latestState0.performanceDaily.initEndDate).format('YYYY-MM-DD')
              : undefined
          })
      );

      const performanceDaily = (await getState()).performanceDaily;

      if (performanceDaily.minDate === 0 && !!performanceDaily.data) {
        // This section extracts the all-time start and end date for data, to be used in performance monthly & yearly
        const yearFormat = locale === 'en-DE' ? 'YYYY-MM-DD' : 'DD-MM-YYYY';
        const listDate = performanceDaily.data.startdate;
        const listDateDayjs = dayjs(listDate, yearFormat);
        const listDateUnix = 1000 * listDateDayjs.unix();
        const lastDate = performanceDaily.data.enddate;
        const lastDateDayjs = dayjs(lastDate, yearFormat);
        const lastDateUnix = 1000 * lastDateDayjs.unix();

        const yearRange: Record<string, string> = {};
        const arrLen = lastDateDayjs.year() - listDateDayjs.year() + 1;
        if (arrLen > 0) {
          Array(arrLen)
            .fill(null)
            .map((val, ix) => {
              const yr = (lastDateDayjs.year() - ix).toString();
              yearRange[yr] = yr;
              return 0;
            });
        }

        updateStateAttribute<State>(
          {
            performanceDaily: {
              ...performanceDaily,
              initStartDate: listDateUnix,
              minDate: listDateUnix,
              initEndDate: lastDateUnix,
              maxDate: lastDateUnix
            },
            performanceMonthly: {
              ...(await getState()).performanceMonthly,
              yearRange,
              initYear: lastDateDayjs.year().toString()
            },
            performanceYearly: {
              ...(await getState()).performanceYearly,
              initYear: lastDateDayjs.year().toString()
            }
          },
          setState
        );
      }

      const latestState = await getState();

      await updateStateResponseStatefulAttribute<State, typeof state.performanceMonthly.data>(
        { performanceMonthly: latestState.performanceMonthly },
        setState,
        async () =>
          await SvSingleStockServices.getSingleStockReportPerformanceMonthly({
            companyId: identifier ?? '',
            locale: locale ?? 'de-DE',
            year: latestState.performanceMonthly.initYear
          })
      );
      await updateStateResponseStatefulAttribute<State, typeof state.performanceMonthly.data>(
        { performanceYearly: latestState.performanceYearly },
        setState,
        async () =>
          await SvSingleStockServices.getSingleStockReportPerformanceYearly({
            companyId: identifier ?? '',
            locale: locale ?? 'de-DE',
            year: latestState.performanceYearly.initYear
          })
      );
      await updateStateResponseStatefulAttribute<State, typeof state.volatility.data>(
        { volatility: latestState.volatility },
        setState,
        async () =>
          await SvSingleStockServices.getSingleStockReportVolatility({
            locale: locale ?? 'de-DE',
            companyId: identifier ?? ''
          })
      );
      await updateStateResponseStatefulAttribute<State, typeof state.drawdown.data>(
        { drawdown: latestState.drawdown },
        setState,
        async () =>
          await SvSingleStockServices.getSingleStockReportDrawdown({
            locale: locale ?? 'de-DE',
            companyId: identifier ?? ''
          })
      );
      await updateStateResponseStatefulAttribute<State, typeof state.dividends.data>(
        { dividends: latestState.dividends },
        setState,
        async () =>
          await SvSingleStockServices.getSingleStockReportDividends({
            locale: locale ?? 'de-DE',
            companyId: identifier ?? ''
          })
      );
      await updateStateResponseStatefulAttribute<State, typeof state.tradingVolumeMarketCap.data>(
        { tradingVolumeMarketCap: latestState.tradingVolumeMarketCap },
        setState,
        async () =>
          await SvSingleStockServices.getSingleStockReportTradingVolumeToMarketCap({
            locale: locale ?? 'de-DE',
            companyId: identifier ?? ''
          })
      );
      await updateStateResponseStatefulAttribute<State, typeof state.relativeTradingVolume.data>(
        { relativeTradingVolume: latestState.relativeTradingVolume },
        setState,
        async () =>
          await SvSingleStockServices.getSingleStockReportRelativeTradingVolume({
            locale: locale ?? 'de-DE',
            companyId: identifier ?? ''
          })
      );
    })();
  }, [locale]);

  return (
    <Page title={state.info.data?.basicinformation.companyname ?? ''}>
      <CPageModule apiRequestState={state.info.state} showContent={!!state.info.data}>
        <div className={'flex flex-col'}>
          <p className={'font-bold'}>{state.info.data?.companyidentifier.tickersymbol}</p>
          <h1 className={'text-teal'}>{state.info.data?.basicinformation.companyname}</h1>
        </div>
        <p>{state.info.data?.businessdescription.busdesc}</p>
      </CPageModule>
      {!!state.info.data && (
        <>
          <CPageModule showContent={!!state.info.data}>
            <BasicInfoModule summarizedInfo={state.info.data} />
          </CPageModule>
          <CPageModule>
            <h3>
              <CLocalizedText dictKey={'singleStockP3H1'} />
            </h3>
            <p>
              <CLocalizedText dictKey={'singleStockP3Desc'} />
            </p>
            <CStatefulContainer apiRequestState={state.info.state} showContent={!!state.info.data}>
              <CKeyEvents info={state.info} />
            </CStatefulContainer>
          </CPageModule>
          <CPageModule fullWidth>
            <CPageModule submodule>
              <h3>
                <CLocalizedText dictKey={'singleStockP4H1'} />
              </h3>
              <p>
                <CLocalizedText dictKey={'singleStockP4Desc'} />
              </p>
            </CPageModule>
            <CStatefulContainer submodule apiRequestState={state.mcap.state} showContent={!!state.mcap.data}>
              <CPageModulePlot data={state.mcap.data?.data} layout={state.mcap.data?.layout} />
            </CStatefulContainer>
          </CPageModule>
          <CPageModule fullWidth>
            <CPageModule submodule>
              <h3>
                <CLocalizedText dictKey={'singleStockP5H1'} />
              </h3>
              <p>
                <CLocalizedText dictKey={'singleStockP5Desc'} />
              </p>
            </CPageModule>
            <CTab submodule>
              <CTabItem label={'daily'} text={<CLocalizedText dictKey={'globalDaily'} />}>
                <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <CPageModule submodule>
                    <p>
                      <CLocalizedText dictKey={'singleStockP5T1Desc'} />
                    </p>
                  </CPageModule>
                  <CStatefulContainer
                    submodule
                    apiRequestState={state.performanceDaily.state}
                    showContent={!!state.performanceDaily.data && state.performanceDaily.minDate > 0}
                  >
                    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <CPageModule submodule>
                        <div className={'flex justify-end'}>
                          <GenericDateRangeSelector
                            onChange={async (startDate, endDate) => {
                              const startDateString = dayjs(startDate).format('YYYY-MM-DD');
                              const endDateString = dayjs(endDate).format('YYYY-MM-DD');
                              await updateStateResponseStatefulAttribute<State, typeof state.performanceDaily.data>(
                                { performanceDaily: (await getState()).performanceDaily },
                                setState,
                                async () =>
                                  await SvSingleStockServices.getSingleStockReportPerformanceDaily({
                                    companyId: identifier ?? '',
                                    startDate: startDateString,
                                    endDate: endDateString,
                                    locale: locale ?? 'de-DE'
                                  })
                              );
                              updateStateAttribute<State>(
                                {
                                  performanceDaily: {
                                    ...(await getState()).performanceDaily,
                                    initStartDate: startDate,
                                    initEndDate: endDate
                                  }
                                },
                                setState
                              );
                            }}
                            initStartDate={state.performanceDaily.initStartDate}
                            initEndDate={state.performanceDaily.initEndDate}
                            minDate={state.performanceDaily.minDate}
                            maxDate={state.performanceDaily.maxDate}
                            minDateSpan={10}
                          />
                        </div>
                      </CPageModule>
                      <CHorizontalScrollContainer>
                        <CPandasTable data={state.performanceDaily.data?.table} />
                      </CHorizontalScrollContainer>
                      <CPageModulePlot
                        data={state.performanceDaily.data?.chart.data}
                        layout={state.performanceDaily.data?.chart.layout}
                      />
                    </CPageModule>
                  </CStatefulContainer>
                </CPageModule>
              </CTabItem>
              <CTabItem label={'monthly'} text={<CLocalizedText dictKey={'globalMonthly'} />}>
                <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <CPageModule submodule>
                    <p>
                      <CLocalizedText dictKey={'singleStockP5T2Desc'} />
                    </p>
                  </CPageModule>
                  <CStatefulContainer
                    submodule
                    apiRequestState={state.performanceMonthly.state}
                    showContent={!!state.performanceMonthly.data}
                  >
                    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <CPageModule submodule>
                        <div className={'flex justify-end'}>
                          <CSortDropdown
                            key={'monthly'}
                            reverseDictOrder
                            labelDesc={(<CLocalizedText dictKey={'globalYearColon'} />) as unknown as string}
                            onChange={async (year) => {
                              await updateStateResponseStatefulAttribute<State, typeof state.performanceMonthly.data>(
                                { performanceMonthly: state.performanceMonthly },
                                setState,
                                async () =>
                                  await SvSingleStockServices.getSingleStockReportPerformanceMonthly({
                                    companyId: identifier ?? '',
                                    locale: locale ?? 'de-DE',
                                    year
                                  })
                              );
                              updateStateAttribute<State>(
                                {
                                  performanceMonthly: {
                                    ...(await getState()).performanceMonthly,
                                    initYear: year
                                  }
                                },
                                setState
                              );
                            }}
                            options={state.performanceMonthly.yearRange}
                            defaultKey={state.performanceMonthly.initYear}
                          />
                        </div>
                      </CPageModule>
                      <CHorizontalScrollContainer>
                        <CPandasTable data={state.performanceMonthly.data?.table} />
                      </CHorizontalScrollContainer>
                      <CPageModulePlot
                        data={state.performanceMonthly.data?.chart.data}
                        layout={state.performanceMonthly.data?.chart.layout}
                      />
                    </CPageModule>
                  </CStatefulContainer>
                </CPageModule>
              </CTabItem>
              <CTabItem label={'yearly'} text={<CLocalizedText dictKey={'globalYearly'} />}>
                <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <CPageModule submodule>
                    <p>
                      <CLocalizedText dictKey={'singleStockP5T3Desc'} />
                    </p>
                  </CPageModule>
                  <CStatefulContainer
                    submodule
                    apiRequestState={state.performanceYearly.state}
                    showContent={!!state.performanceYearly.data}
                  >
                    <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <CPageModule submodule>
                        <div className={'flex justify-end'}>
                          <CSortDropdown
                            key={'yearly'}
                            labelDesc={(<CLocalizedText dictKey={'globalYearColon'} />) as unknown as string}
                            onChange={async (year) => {
                              await updateStateResponseStatefulAttribute<State, typeof state.performanceYearly.data>(
                                { performanceYearly: state.performanceYearly },
                                setState,
                                async () =>
                                  await SvSingleStockServices.getSingleStockReportPerformanceYearly({
                                    companyId: identifier ?? '',
                                    locale: locale ?? 'de-DE',
                                    year
                                  })
                              );
                              updateStateAttribute<State>(
                                {
                                  performanceYearly: {
                                    ...(await getState()).performanceYearly,
                                    initYear: year
                                  }
                                },
                                setState
                              );
                            }}
                            reverseDictOrder
                            options={state.performanceMonthly.yearRange}
                            defaultKey={state.performanceYearly.initYear}
                          />
                        </div>
                      </CPageModule>
                      <CHorizontalScrollContainer>
                        <CPandasTable data={state.performanceYearly.data?.table} />
                      </CHorizontalScrollContainer>
                      <CPageModulePlot
                        data={state.performanceYearly.data?.chart.data}
                        layout={state.performanceYearly.data?.chart.layout}
                      />
                      <CPageModulePlot
                        data={state.performanceYearly.data?.bars.data}
                        layout={state.performanceYearly.data?.bars.layout}
                      />
                    </CPageModule>
                  </CStatefulContainer>
                </CPageModule>
              </CTabItem>
            </CTab>
          </CPageModule>
          <CPageModule fullWidth>
            <CPageModule submodule>
              <h3>
                <CLocalizedText dictKey={'singleStockP6H1'} />
              </h3>
              <p>
                <CLocalizedText dictKey={'singleStockP6Desc'} />
              </p>
            </CPageModule>
            <CStatefulContainer
              apiRequestState={state.volatility.state}
              showContent={!!state.volatility.data}
              submodule
            >
              <CPageModulePlot data={state.volatility.data?.data} layout={state.volatility.data?.layout} />
            </CStatefulContainer>
          </CPageModule>
          <CPageModule fullWidth>
            <CPageModule submodule>
              <h3>
                <CLocalizedText dictKey={'singleStockP7H1'} />
              </h3>
              <p>
                <CLocalizedText dictKey={'singleStockP7Desc'} />
              </p>
            </CPageModule>
            <CStatefulContainer apiRequestState={state.drawdown.state} showContent={!!state.drawdown.data} submodule>
              <CPageModule fullWidth style={{ paddingTop: 0, paddingBottom: 0 }}>
                <CHorizontalScrollContainer>
                  <CPandasTable data={state.drawdown.data?.table} showIndex />
                </CHorizontalScrollContainer>
                <CPageModulePlot data={state.drawdown.data?.chart.data} layout={state.drawdown.data?.chart.layout} />
              </CPageModule>
            </CStatefulContainer>
          </CPageModule>
          <CPageModule fullWidth>
            <CPageModule submodule>
              <h3>
                <CLocalizedText dictKey={'singleStockP8H1'} />
              </h3>
              <p>
                <CLocalizedText dictKey={'singleStockP8Desc'} />
              </p>
            </CPageModule>
            <CStatefulContainer apiRequestState={state.dividends.state} showContent={!!state.dividends.data} submodule>
              <CPageModulePlot data={state.dividends.data?.data} layout={state.dividends.data?.layout} />
            </CStatefulContainer>
          </CPageModule>
          <CPageModule fullWidth>
            <CPageModule submodule>
              <h3>
                <CLocalizedText dictKey={'singleStockP9H1'} />
              </h3>
              <p>
                <CLocalizedText dictKey={'singleStockP9Desc'} />
              </p>
            </CPageModule>
            <CStatefulContainer
              apiRequestState={state.tradingVolumeMarketCap.state}
              showContent={!!state.tradingVolumeMarketCap.data}
              submodule
            >
              <CPageModulePlot
                data={state.tradingVolumeMarketCap.data?.data}
                layout={state.tradingVolumeMarketCap.data?.layout}
              />
            </CStatefulContainer>
          </CPageModule>
          <CPageModule fullWidth>
            <CPageModule submodule>
              <h3>
                <CLocalizedText dictKey={'singleStockP10H1'} />
              </h3>
              <p>
                <CLocalizedText dictKey={'singleStockP10Desc'} />
              </p>
            </CPageModule>
            <CStatefulContainer
              apiRequestState={state.relativeTradingVolume.state}
              showContent={!!state.relativeTradingVolume.data}
              submodule
            >
              <CPageModulePlot
                data={state.relativeTradingVolume.data?.data}
                layout={state.relativeTradingVolume.data?.layout}
              />
            </CStatefulContainer>
          </CPageModule>
        </>
      )}
    </Page>
  );
}
