import { useKeycloak } from '@react-keycloak/web';
import React from 'react';

export default function RouteFactsheet({ children }: { children: JSX.Element }) {
  const { keycloak } = useKeycloak();

  if (window.localStorage.getItem('factsheet-terms-accepted') ?? keycloak.authenticated) {
    return children;
  } else {
    keycloak.login().then();
    return <></>;
  }
}
