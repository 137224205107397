import {
  BuildingLibraryIcon,
  ChatBubbleLeftRightIcon,
  GlobeEuropeAfricaIcon,
  PresentationChartLineIcon,
  ScaleIcon,
  Square3Stack3DIcon
} from '@heroicons/react/24/outline';
import { OpenInNew } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CFactsheetDisclaimer from '../../components/CFactsheetDisclaimer';
import CLocalizedText from '../../components/CLocalizedText';
import CPageModule from '../../components/CPageModule';
import CPageModulePlot from '../../components/CPageModulePlot';
import CPandasTable from '../../components/CPandasTable';
import CTextFieldDisplay from '../../components/CTextFieldDisplay';
import PageModuleFormGroup from '../../components/PageModuleFormGroup';
import ActionLinkButton from '../../components/dashboardContent/ActionLinkButton';
import Page from '../../components/layout/Page';
import XDResponsiveGridWrapper from '../../components/layout/XDResponsiveGridWrapper';
import { PageRoutes } from '../../enums/enums';
import { updateState, useStateExtended } from '../../helpers/helper';
import { useAppSelector } from '../../hooks/hooks';
import { type IRequestState } from '../../models/IRequestState';
import { type PlotlyResponse } from '../../models/PlotlyModel';
import { type TPandasTable } from '../../models/TPandasTable';
import PublicFactsheetServices from '../../services/publicFactsheetServices';

interface State {
  states?: Partial<{ overview: IRequestState; overviewTable: IRequestState }>;
  response?: Partial<{ overview: PlotlyResponse; overviewTable: TPandasTable }>;
}

export default function PFactsheetIndex() {
  const { locale } = useAppSelector((state) => state.dashboard);
  const { factsheetId } = useParams();
  const [state, setState, getState] = useStateExtended<State>({
    states: {
      overview: { isLoading: true, isError: false },
      overviewTable: { isLoading: true, isError: false }
    }
  });

  const fetchCharts = async () => {
    updateState<State>(
      {
        states: {
          overview: { ...(await getState()).states, isLoading: true, isError: state.states?.overview?.isError ?? false }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getFactsheetChart(factsheetId ?? '', locale);
    updateState<State>(
      {
        states: {
          overview: {
            ...(await getState()).states,
            isLoading: false,
            isError: res.hasError(),
            strError: res.getErrorString()
          }
        }
      },
      state,
      setState
    );
    if (res.wasSuccessful()) {
      updateState<State>({ response: { ...(await getState()).response, overview: res.getData() } }, state, setState);
    }
  };

  const fetchTable = async () => {
    updateState<State>(
      {
        states: {
          ...(await getState()).states,
          overviewTable: { isLoading: true, isError: state.states?.overviewTable?.isError ?? false }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getFactsheetOverviewTable(factsheetId ?? '', locale);
    updateState<State>(
      {
        states: {
          ...(await getState()).states,
          overviewTable: { isLoading: false, isError: res.hasError(), strError: res.getErrorString() }
        }
      },
      state,
      setState
    );
    if (res.wasSuccessful()) {
      updateState<State>(
        { response: { ...(await getState()).response, overviewTable: res.getData() } },
        state,
        setState
      );
    }
  };

  useEffect(() => {
    (async () => {
      await fetchTable();
      await fetchCharts();
    })();
  }, [locale]);

  return (
    <Page>
      <CPageModule>
        <h2>
          <CLocalizedText dictKey={'fsovAbout'} />
        </h2>
        <CLocalizedText dictKey={'fsovFirm'} />
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fsovTitle'} />
          </h3>
          <CLocalizedText dictKey={'fsovDesc'} />
        </CPageModule>
        <CPageModule submodule>
          <h3 className={'flex justify-start'}>
            <a
              href={'https://fondsfinder.universal-investment.com/?returnUrl=Funds%2fDE000A3D9F52%3f'}
              target={'_blank'}
              rel="noreferrer"
              className={'items-center text-xl flex gap-2 font-normal'}
            >
              <CLocalizedText dictKey={'fsovDescLink'} />
              <OpenInNew className={'-mt-1'} />
            </a>
          </h3>
        </CPageModule>
        <CPageModulePlot
          data={state.response?.overview?.data}
          layout={state.response?.overview?.layout}
          apiRequestState={state.states?.overview}
          showWhen={!!state.response?.overview}
          aspectRatio={2}
        />
        <CPageModule submodule>
          <div className={'grid grid-cols-1 lg:grid-cols-2 gap-8'}>
            <div className={'col-span-1 flex flex-col gap-6'}>
              <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovAlloc'} />}>
                <CLocalizedText dictKey={'fsovAllocB1'} />
              </CTextFieldDisplay>
              <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovAlloc'} />}>
                <CLocalizedText dictKey={'fsovAllocB2'} />
              </CTextFieldDisplay>
              <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovAlloc'} />}>
                <CLocalizedText dictKey={'fsovAllocB3'} />
              </CTextFieldDisplay>
            </div>
            <div className={'col-span-1'}>
              <CPandasTable
                data={state.response?.overviewTable}
                style={{ minWidth: 'auto', width: '100%' }}
                colSpanArr={[1, 1, 1]}
                showIndex
              />
            </div>
          </div>
        </CPageModule>
      </CPageModule>
      <CPageModule>
        <h3>
          <CLocalizedText dictKey={'fsovP2H1'} />
        </h3>
        <XDResponsiveGridWrapper widerBox>
          <ActionLinkButton
            label={<CLocalizedText dictKey={'fsovP2Performance'} />}
            to={PageRoutes.PUBLIC_FACTSHEET_PERFORMANCE}
          >
            <PresentationChartLineIcon />
          </ActionLinkButton>
          <ActionLinkButton
            label={<CLocalizedText dictKey={'globalFactsheetSectionFacex'} />}
            to={PageRoutes.PUBLIC_FACTSHEET_FACTOR_EXPOSURE}
          >
            <Square3Stack3DIcon />
          </ActionLinkButton>
          <ActionLinkButton
            label={<CLocalizedText dictKey={'globalFactsheetSectionSecex'} />}
            to={PageRoutes.PUBLIC_FACTSHEET_SECTOR_EXPOSURE}
          >
            <BuildingLibraryIcon />
          </ActionLinkButton>
          <ActionLinkButton
            label={<CLocalizedText dictKey={'fsovP2Attribution'} />}
            to={PageRoutes.PUBLIC_FACTSHEET_PERFORMANCE_ATTRIBUTION}
          >
            <ChatBubbleLeftRightIcon />
          </ActionLinkButton>
          <ActionLinkButton
            label={<CLocalizedText dictKey={'globalFactsheetSectionDelta'} />}
            to={PageRoutes.PUBLIC_FACTSHEET_DELTA}
          >
            <ScaleIcon />
          </ActionLinkButton>
          <ActionLinkButton
            label={<CLocalizedText dictKey={'globalFactsheetSectionEsg'} />}
            to={PageRoutes.PUBLIC_FACTSHEET_ESG}
          >
            <GlobeEuropeAfricaIcon />
          </ActionLinkButton>
        </XDResponsiveGridWrapper>
      </CPageModule>
      <CPageModule>
        <h3>
          <CLocalizedText dictKey={'fsovP3H1'} />
        </h3>
        <div className={'grid grid-cols-1 lg:grid-cols-3 gap-8 gap-y-6'}>
          <div className={'flex flex-col gap-6 col-span-1'}>
            <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-8 gap-y-4'}>
              <PageModuleFormGroup>
                <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3IsinKey'} />}>
                  <CLocalizedText dictKey={'fsovP3IsinVal'} />
                </CTextFieldDisplay>
                <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3DomicileKey'} />}>
                  <CLocalizedText dictKey={'fsovP3DomicileVal'} />
                </CTextFieldDisplay>
              </PageModuleFormGroup>
              <PageModuleFormGroup>
                <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3FundCatKey'} />}>
                  <CLocalizedText dictKey={'fsovP3FundCatVal'} />
                </CTextFieldDisplay>
                <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3CurFundClassKey'} />}>
                  <CLocalizedText dictKey={'fsovP3CurFundClassVal'} />
                </CTextFieldDisplay>
              </PageModuleFormGroup>
            </div>
          </div>
          <div className={'col-span-1 lg:col-span-2 flex flex-col gap-6'}>
            <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-4'}>
              <PageModuleFormGroup>
                <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3Launch'} />}>
                  {new Date('6 May 2024').toLocaleDateString(locale)}
                </CTextFieldDisplay>
                <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3LaunchClass'} />}>
                  {new Date('6 May 2024').toLocaleDateString(locale)}
                </CTextFieldDisplay>
                <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3EsgRatingKey'} />}>
                  <CLocalizedText dictKey={'fsovP3EsgRatingVal'} />
                </CTextFieldDisplay>
                <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3UtilOfIncKey'} />}>
                  <CLocalizedText dictKey={'fsovP3UtilOfIncVal'} />
                </CTextFieldDisplay>
              </PageModuleFormGroup>
              <PageModuleFormGroup>
                <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3MaxManFeeKey'} />}>
                  <CLocalizedText dictKey={'fsovP3MaxManFeeVal'} />
                </CTextFieldDisplay>
                <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3MaxDepFeeKey'} />}>
                  <CLocalizedText dictKey={'fsovP3MaxDepFeeVal'} />
                </CTextFieldDisplay>
                <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3OngoingCostKey'} />}>
                  <CLocalizedText dictKey={'fsovP3OngoingCostVal'} />
                </CTextFieldDisplay>
                <CTextFieldDisplay label={<CLocalizedText dictKey={'fsovP3MaxAdvFeeKey'} />}>
                  <CLocalizedText dictKey={'fsovP3MaxAdvFeeVal'} />
                </CTextFieldDisplay>
              </PageModuleFormGroup>
            </div>
          </div>
        </div>
      </CPageModule>
      <CPageModule>
        <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 gap-y-6'}>
          <div className={'flex flex-col gap-6'}>
            <h3>
              <CLocalizedText dictKey={'fsovP4H1'} />
            </h3>
            <CLocalizedText dictKey={'fsovP4Desc'} />
          </div>
          <div className={'flex flex-col gap-6 lg:col-span-2'}>
            <h3>
              <CLocalizedText dictKey={'fsovP5H1'} />
            </h3>
            <CLocalizedText dictKey={'fsovP5Desc'} />
          </div>
        </div>
      </CPageModule>
      <CPageModule>
        <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 gap-y-6'}>
          <div className={'flex flex-col gap-6'}>
            <h3>
              <CLocalizedText dictKey={'fsovP8H1'} />
            </h3>
            <CLocalizedText dictKey={'fsovP8Desc'} />
          </div>{' '}
          <div className={'flex flex-col gap-6'}>
            <h3>
              <CLocalizedText dictKey={'fsovP6H1'} />
            </h3>
            <CLocalizedText dictKey={'fsovP6Desc'} />
          </div>
          <div className={'flex flex-col gap-6'}>
            <h3>
              <CLocalizedText dictKey={'fsovP7H1'} />
            </h3>
            <CLocalizedText dictKey={'fsovP7Desc'} />
          </div>
        </div>
      </CPageModule>
      <CPageModule style={{ paddingTop: 0, height: 0 }} />
      <CFactsheetDisclaimer />
    </Page>
  );
}
