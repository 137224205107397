import { Cancel } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CFactsheetDisclaimer from '../../components/CFactsheetDisclaimer';
import CLocalizedText from '../../components/CLocalizedText';
import CLocalizedTextString from '../../components/CLocalizedTextString';
import CPageModule from '../../components/CPageModule';
import CPageModulePaginatedTable from '../../components/CPageModulePaginatedTable';
import CPageModulePlot from '../../components/CPageModulePlot';
import CPandasTable from '../../components/CPandasTable';
import CPlot from '../../components/CPlot';
import CSectionNavigator from '../../components/CSectionNavigator';
import CStatefulContainer from '../../components/CStatefulContainer';
import Page from '../../components/layout/Page';
import { PageRoutes } from '../../enums/enums';
import { updateState, useStateExtended } from '../../helpers/helper';
import { useAppSelector } from '../../hooks/hooks';
import { type IAugmentedBackendResponse } from '../../models/IAugmentedBackendResponse';
import { type IRequestState } from '../../models/IRequestState';
import { type ISortingMeta } from '../../models/ISortingMeta';
import { type ITableOrPlotState } from '../../models/ITableOrPlotState';
import { type ITemperatureScore } from '../../models/ITemperatureScore';
import { type TPandasTable } from '../../models/TPandasTable';
import { type TEsgPillars } from '../../models/factsheet/TEsgPillars';
import PublicFactsheetServices from '../../services/publicFactsheetServices';

interface State {
  esgPerformance?: Partial<{
    data: TEsgPillars;
    state: IRequestState;
  }>;
  esgRisk?: Partial<{
    data: TEsgPillars;
    state: IRequestState;
  }>;
  exclusionTable: ITableOrPlotState<IAugmentedBackendResponse<TPandasTable>>;
  temperatureScore?: Partial<{
    data: ITemperatureScore;
    state: IRequestState;
  }>;
}

export default function PESG() {
  const { locale } = useAppSelector((state) => state.dashboard);
  const { factsheetId } = useParams();
  const [state, setState, getState] = useStateExtended<State>({
    esgPerformance: {
      state: {
        isLoading: true,
        isError: false
      }
    },
    esgRisk: {
      state: {
        isLoading: true,
        isError: false
      }
    },
    exclusionTable: {
      state: {
        isLoading: true,
        isError: false
      },
      params: {
        sorting: { sortCol: 0, ascending: 'ascending' }
      },
      response: undefined
    },
    temperatureScore: {
      state: {
        isLoading: true,
        isError: false
      }
    }
  });
  const sectionNavigator = (
    <CSectionNavigator
      previous={{
        label: <CLocalizedText dictKey={'globalFactsheetSectionDelta'} />,
        url: `../${PageRoutes.PUBLIC_FACTSHEET_DELTA}`
      }}
      next={{
        label: <CLocalizedText dictKey={'globalSectionFactsheetOverview'} />,
        url: '..'
      }}
    />
  );

  const fetchEsgPerformance = async () => {
    updateState<State>(
      {
        esgPerformance: {
          ...(await getState()).esgPerformance,
          state: { isLoading: true, isError: (await getState())?.esgPerformance?.state?.isError ?? false }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getEsgPerformance(factsheetId ?? '', locale);
    updateState<State>(
      {
        esgPerformance: {
          ...(await getState()).esgPerformance,
          state: { isLoading: false, isError: res.hasError(), strError: res.getErrorString() }
        }
      },
      state,
      setState
    );
    if (res.wasSuccessful()) {
      updateState<State>(
        { esgPerformance: { ...(await getState()).esgPerformance, data: res.getData() } },
        state,
        setState
      );
    }
  };

  const fetchEsgRisk = async () => {
    updateState<State>(
      {
        esgRisk: {
          ...(await getState()).esgRisk,
          state: { isLoading: true, isError: (await getState())?.esgRisk?.state?.isError ?? false }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getEsgRisk(factsheetId ?? '', locale);
    updateState<State>(
      {
        esgRisk: {
          ...(await getState()).esgRisk,
          state: { isLoading: false, isError: res.hasError(), strError: res.getErrorString() }
        }
      },
      state,
      setState
    );
    if (res.wasSuccessful()) {
      updateState<State>({ esgRisk: { ...(await getState()).esgRisk, data: res.getData() } }, state, setState);
    }
  };

  const formatEsgResponse = (data: TPandasTable) => {
    const newData: TPandasTable = {};
    Object.keys(data).map((e, ix) => {
      const newRow: Record<string, any> = {};
      const row = data[e];
      Object.keys(Object.values(data)[ix]).map((f) => {
        if (typeof row[f] === 'number') {
          if (row[f] === 1)
            newRow[f] = (
              <span className={'text-xl p-2 text-teal-ppt-4'}>
                <Cancel />
              </span>
            );
          else newRow[f] = '';
        } else {
          newRow[f] = row[f];
        }
        return '';
      });
      newData[e] = newRow;
      return '';
    });
    return newData;
  };

  const fetchExclusionTable = async (page: number, sorting: ISortingMeta, search?: string, filters?: string[]) => {
    const lastState = (await getState()).exclusionTable;
    updateState<State>(
      {
        exclusionTable: {
          ...lastState,
          state: { isLoading: true, isError: lastState.state.isError }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getExclusionTable(
      factsheetId ?? '',
      page,
      sorting,
      search,
      filters,
      locale
    );
    if (res.wasSuccessful()) {
      updateState<State>(
        {
          exclusionTable: {
            ...lastState,
            response: {
              meta: res.getData().meta,
              results: formatEsgResponse(res.getData().results)
            },
            state: { isLoading: false, isError: false },
            params: { sorting, search }
          }
        },
        state,
        setState
      );
    } else {
      updateState<State>(
        {
          exclusionTable: {
            ...lastState,
            state: {
              isLoading: false,
              isError: res.hasError(),
              strError: res.getErrorString(),
              objError: res.getErrorObj()
            }
          }
        },
        state,
        setState
      );
    }
  };

  const fetchTemperatureScore = async () => {
    updateState<State>(
      {
        temperatureScore: {
          ...(await getState()).temperatureScore,
          state: { isLoading: true, isError: (await getState())?.temperatureScore?.state?.isError ?? false }
        }
      },
      state,
      setState
    );
    const res = await PublicFactsheetServices.getTemperatureScore(factsheetId ?? '', locale);
    updateState<State>(
      {
        temperatureScore: {
          ...(await getState()).temperatureScore,
          state: { isLoading: false, isError: res.hasError(), strError: res.getErrorString() }
        }
      },
      state,
      setState
    );
    if (res.wasSuccessful()) {
      updateState<State>(
        { temperatureScore: { ...(await getState()).temperatureScore, data: res.getData() } },
        state,
        setState
      );
    }
  };

  useEffect(() => {
    (async () => {
      await fetchEsgPerformance();
      await fetchEsgRisk();
      await fetchTemperatureScore();

      const exclusionTableState = (await getState()).exclusionTable;
      await fetchExclusionTable(
        exclusionTableState.response?.meta.pagination.page ?? 1,
        exclusionTableState.params.sorting ?? { sortCol: 0, ascending: 'ascending' },
        exclusionTableState.params.search,
        exclusionTableState.response?.meta.filter.applied?.split(',')
      );
    })();
  }, [locale]);

  return (
    <Page title={CLocalizedTextString('globalFactsheetSectionEsg')}>
      <CPageModule>{sectionNavigator}</CPageModule>
      <CPageModule>
        <h2>
          <CLocalizedText dictKey={'fsesgP1H1'} />
        </h2>
        <CLocalizedText dictKey={'fsesgP1Desc'} />
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fsesgP2H1'} />
          </h3>
          <CLocalizedText dictKey={'fsesgP2Desc'} />
        </CPageModule>
        <CEsgPillars data={state.esgPerformance?.data} apiReqState={state.esgPerformance?.state} />
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fsesgP3H1'} />
          </h3>
          <CLocalizedText dictKey={'fsesgP3Desc'} />
        </CPageModule>
        <CEsgPillars data={state.esgRisk?.data} apiReqState={state.esgRisk?.state} />
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fsesgP4H1'} />
          </h3>
          <CLocalizedText dictKey={'fsesgP4Desc'} />
          {/* <div className={'flex justify-end'}> */}
          {/*   <CSortDropdown */}
          {/*     onChange={async (sortBy) => { */}
          {/*       updateState<State>({ exclusionTable: { ...state.exclusionTable, sortBy } }, state, setState); */}
          {/*       await fetchExclusionTable(1, sortBy); */}
          {/*     }} */}
          {/*     options={{ */}
          {/*       'Company Name': <CLocalizedText dictKey={'globalSortByCompanyNameAsc'} />, */}
          {/*       'UN Global Compact Violation': <CLocalizedText dictKey={'fsesgP4UnGlobalCompactVio'} />, */}
          {/*       'Controversial Weapons Involvement': <CLocalizedText dictKey={'fsesgP4ControvWeaponsInv'} />, */}
          {/*       'Defence Involvement': <CLocalizedText dictKey={'fsesgP4DefInv'} />, */}
          {/*       'Weapons Involvement': <CLocalizedText dictKey={'fsesgP4WeaponsInv'} />, */}
          {/*       'Fossil Fuels Involvement': <CLocalizedText dictKey={'fsesgP4FossilFuelsInv'} />, */}
          {/*       'Tobacco Involvement': <CLocalizedText dictKey={'fsesgP4TobaccoInv'} /> */}
          {/*     }} */}
          {/*     defaultKey={'Company Name'} */}
          {/*   /> */}
          {/* </div> */}
        </CPageModule>
        <CStatefulContainer
          submodule
          apiRequestState={state.exclusionTable?.state}
          showContent={!!state.exclusionTable?.response}
        >
          <CPageModulePaginatedTable
            state={state.exclusionTable?.state}
            data={state.exclusionTable?.response}
            params={state.exclusionTable.params}
            searchBarName={'exclusionTable'}
            colSpanArr={[5, 4, 2, 2, 2, 2, 2, 2]}
            onChange={async (page, sorting, searchText, filters) => {
              await fetchExclusionTable(page, sorting, searchText, filters);
            }}
            cellHeight={'3.5rem'}
          />
        </CStatefulContainer>
      </CPageModule>
      <CPageModule fullWidth>
        <CPageModule submodule>
          <h3>
            <CLocalizedText dictKey={'fsesgP5H1'} />
          </h3>
          <CLocalizedText dictKey={'fsesgP5Desc'} />
          <h4>
            <CLocalizedText dictKey={'fsesgP5P1H1'} />
          </h4>
          <CLocalizedText dictKey={'fsesgP5P1Desc'} />
        </CPageModule>
        <CPageModulePlot
          data={state.temperatureScore?.data?.near.data}
          layout={state.temperatureScore?.data?.near.layout}
          apiRequestState={state.temperatureScore?.state}
          showWhen={!!state.temperatureScore?.data}
        />
        <CPageModule submodule>
          <h4>
            <CLocalizedText dictKey={'fsesgP5P2H1'} />
          </h4>
          <CLocalizedText dictKey={'fsesgP5P2Desc'} />
        </CPageModule>
        <CPageModulePlot
          data={state.temperatureScore?.data?.far.data}
          layout={state.temperatureScore?.data?.far.layout}
          apiRequestState={state.temperatureScore?.state}
          showWhen={!!state.temperatureScore?.data}
        />
      </CPageModule>
      <CPageModule>{sectionNavigator}</CPageModule>
      <CFactsheetDisclaimer />
    </Page>
  );
}

interface IEsgPillarsProps {
  data: TEsgPillars | undefined;
  apiReqState: IRequestState | undefined;
}

function CEsgPillars(props: IEsgPillarsProps) {
  const plotDim = 100;

  return (
    <>
      {!(!props.apiReqState?.isLoading && !props.apiReqState?.isError) && (
        <CPageModule apiRequestState={props.apiReqState} submodule />
      )}
      {!props.apiReqState?.isLoading && !props.apiReqState?.isError && (
        <>
          {Object.keys(props.data ?? {}).map((pillarKey, ix) => {
            const pillar = Object.values(props.data ?? {})[ix];
            return (
              <CPageModule key={pillarKey} fullWidth submodule>
                <CPageModule submodule>
                  <h3>{pillarKey}</h3>
                  <div className={'flex flex-col sm:flex-row gap-8'} style={{ hyphens: 'none' }}>
                    <div className={'flex gap-4'}>
                      <div className={'flex flex-col gap-2 items-center'}>
                        <CPlot
                          data={pillar.universe.data}
                          layout={{ ...pillar.universe.layout, width: plotDim, height: plotDim, title: '' }}
                        />
                        <p className={'text-sm text-center font-bold'}>
                          <CLocalizedText dictKey={'globalInvestableUsUniverse'} />
                        </p>
                      </div>
                      <div className={'flex flex-col gap-2 items-center'}>
                        <CPlot
                          data={pillar.swap.data}
                          layout={{ ...pillar.swap.layout, width: plotDim, height: plotDim }}
                        />
                        <p className={'text-sm text-center font-bold'}>
                          <CLocalizedText dictKey={'globalNexdosSwapAlloc'} />
                        </p>
                      </div>
                    </div>
                    <div>
                      {pillar.table && (
                        <CPandasTable
                          data={pillar.table}
                          style={{ minWidth: 'auto' }}
                          showIndex
                          colSpanArr={[3, 1, 1]}
                        />
                      )}
                    </div>
                  </div>
                </CPageModule>
                <div />
                <CPageModulePlot data={pillar.histogram.data} layout={pillar.histogram.layout} />
              </CPageModule>
            );
          })}
        </>
      )}
    </>
  );
}
