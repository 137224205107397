import React from 'react';

import CLocalizedText from '../../../components/CLocalizedText';
import { type IPageModule } from '../../../components/CPageModule';
import CTextFieldDisplay from '../../../components/CTextFieldDisplay';
import PageModuleFormGroup from '../../../components/PageModuleFormGroup';
import { type MSingleStockSummarizedInfo } from '../../../models/singleStock/details/MSingleStockSummarizedInfo';

interface props extends Pick<IPageModule, 'divRef'> {
  summarizedInfo?: MSingleStockSummarizedInfo;
}

export default function BasicInfoModule(props: props) {
  const computeAddress = () => {
    const line1 = [props.summarizedInfo?.basicinformation.streetaddress]
      .filter((e) => !e?.includes('Not disclosed'))
      .join('');
    const line2 = [props.summarizedInfo?.basicinformation.streetaddress2]
      .filter((e) => !e?.includes('Not disclosed'))
      .join('');
    const line3 = [
      props.summarizedInfo?.basicinformation.city,
      [props.summarizedInfo?.basicinformation.state, props.summarizedInfo?.basicinformation.zipcode]
        .filter((e) => !e?.includes('Not disclosed'))
        .join(' ')
    ]
      .filter((e) => !e?.includes('Not disclosed'))
      .join(', ');
    const line4 = [props.summarizedInfo?.basicinformation.country]
      .filter((e) => !e?.includes('Not disclosed'))
      .join('');
    const addr = [line1, line2, line3, line4].filter((e) => e);
    if (addr.length === 0) addr.push('Not disclosed');
    return addr;
  };

  const address = computeAddress();

  return (
    <div className={'grid grid-cols-1 lg:grid-cols-3 gap-8'}>
      <div className={'flex flex-col gap-6 col-span-1'}>
        <h3>
          <CLocalizedText dictKey={'singleStockP2H1'} />
        </h3>
        <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-8 gap-y-4'}>
          <PageModuleFormGroup>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalMarketCapitalization'} />}>
              {props.summarizedInfo?.formattedmarketcap}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalType'} />}>
              {props.summarizedInfo?.basicinformation.companytypename}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalFounded'} />}>
              {props.summarizedInfo?.basicinformation.yearfounded}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalStatus'} />}>
              {props.summarizedInfo?.basicinformation.companystatustypename}
            </CTextFieldDisplay>
          </PageModuleFormGroup>
          <PageModuleFormGroup>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalAddress'} />}>
              {
                <div className={'flex flex-col gap-0'}>
                  {address.map((e) => (
                    <span key={e}>{e}</span>
                  ))}
                </div>
              }
            </CTextFieldDisplay>
            {!props.summarizedInfo?.basicinformation.webpage?.includes('Not disclosed') && (
              <CTextFieldDisplay label={<CLocalizedText dictKey={'globalWebsite'} />}>
                <a href={`http://${props.summarizedInfo?.basicinformation.webpage}`}>
                  {props.summarizedInfo?.basicinformation.webpage}
                </a>
              </CTextFieldDisplay>
            )}
          </PageModuleFormGroup>
        </div>
      </div>
      <div className={'col-span-1 lg:col-span-2 flex flex-col gap-6'}>
        <h3>
          <CLocalizedText dictKey={'singleStockP2H2'} />
        </h3>
        <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-4'}>
          <PageModuleFormGroup>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalCompanyId'} />}>
              {props.summarizedInfo?.companyidentifier.companyid}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalSecurityId'} />}>
              {props.summarizedInfo?.companyidentifier.securityid}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalTradingItemId'} />}>
              {props.summarizedInfo?.companyidentifier.tradingitemid}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalGvkey'} />}>
              {props.summarizedInfo?.companyidentifier.gvkey}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalCik'} />}>
              {props.summarizedInfo?.companyidentifier.cik}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalCusip'} />}>
              {props.summarizedInfo?.companyidentifier.cusip}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalIsin'} />}>
              {props.summarizedInfo?.companyidentifier.isin}
            </CTextFieldDisplay>
          </PageModuleFormGroup>
          <PageModuleFormGroup>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalExchange'} />}>
              {props.summarizedInfo?.exchangename}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalTickerSymbol'} />}>
              {props.summarizedInfo?.companyidentifier.tickersymbol}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalTradingStatus'} />}>
              {props.summarizedInfo?.tradingitemstatusname}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalSectorClassification'} />}>
              {props.summarizedInfo?.currenthistoricalinformation?.[0]?.gsector}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalGroupClassification'} />}>
              {props.summarizedInfo?.currenthistoricalinformation?.[0]?.ggroup}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalIndustryClassification'} />}>
              {props.summarizedInfo?.currenthistoricalinformation?.[0]?.gind}
            </CTextFieldDisplay>
            <CTextFieldDisplay label={<CLocalizedText dictKey={'globalSubIndustryClassification'} />}>
              {props.summarizedInfo?.currenthistoricalinformation?.[0]?.gsubind}
            </CTextFieldDisplay>
          </PageModuleFormGroup>
        </div>
      </div>
    </div>
  );
}
